import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import DefaultLayout from "../layouts/defaultLayout"

const NotFoundPage = () => (
  <DefaultLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
    <Link to="/">Click here to go back to the main page</Link>
  </DefaultLayout>
)

export default NotFoundPage
